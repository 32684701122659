@import "base";
@import "variables";
@import "functions";

.navbar {
  position: relative;
  height: 3rem;
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: rgb(250, 250, 250);
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(104, 104, 104, 0.26);
  &-logo {
    color: $pink-color;
    font-size: 2rem;
    font-family: "Mr Dafoe", cursive;
    transition: $maintransition;
  }
  &-container {
    max-width: 1700px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  &-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.75rem;
  }
  &-link {
    text-decoration: inherit;
    margin: 0 1rem;
    // color: rgb(29, 29, 20);
    color: $blue-color;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      transition: $maintransition;
      height: 0.2rem;
      width: 0;
      bottom: -0.3rem;
      left: 0;
      right: 0;
      opacity: 0;
      background-color: $pink-color;
    }
    &:hover {
      &:after {
        opacity: 1;
        width: 100%;
      }
    }
  }
  &-name {
    text-decoration: inherit;
    color: $pink-color;
  }
}

.searchbar {
  margin-top: 1rem;
  height: 2rem;
  width: 15rem;
  display: inline-block;
}

.hamburger {
  font-size: 2rem;
  color: #da3b6bff;
  cursor: pointer;
  z-index: 10;
  right: 2%;
  display: none;
  position: fixed;
}

//Splitscreen

.veg {
  position: absolute;
  object-fit: cover;
}

.split {
  height: 100vh;
  width: 100vw;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  &-left {
    @include flexCenterCol;
    transition: $splittransition;
    position: absolute;
    height: 100%;
    width: 50%;
    background: url(../assets/img/fruit.jpg) no-repeat center 10% / cover;

    &-hover {
      width: 51%;
    }
  }
  &-right {
    @include flexCenterCol;
    transition: $splittransition;
    position: absolute;
    left: 50%;
    height: 100%;
    width: 50%;
    background: url(../assets/img/meat.jpg) no-repeat center center / cover;
    &-hover {
      width: 52%;
    }
  }
}

.title {
  text-transform: uppercase;
  color: $offwhite;
}

.banner {
  height: 100%;
}

.about {
  grid-column: 1 / 3;
  @media screen and (max-width: 800px) {
    grid-column: auto;
  }
}

//Overlays

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  h5 {
    text-transform: uppercase;
    color: $blue-color;
    font-size: 2rem;
    font-weight: 600;
    height: 2rem;
  }
  i {
    font-size: 5rem;
    color: $blue-color;
    margin-bottom: 2rem;
    cursor: auto;
  }
}

.split-title {
  font-family: "Mr Dafoe", cursive;
  text-transform: capitalize;
  color: $blue-color;
  font-size: 4rem;
  font-weight: 600;
  height: 2rem;
  margin-bottom: 4rem;
}

//Heros
.defaultHero {
  background: url(../assets/img/berries.jpg) center center / cover no-repeat;
  height: 100vh;
  @include flexCenterCol;
}

.tableHero {
  background: url(../assets/img/table.jpg) no-repeat center center / cover;
  height: 60vh;
  @include flexCenterCol;
}

.brandHero {
  background-color: #fff;
  font-family: "Mr Dafoe", cursive;
  height: 30vh;
  color: $pink-color;
  font-size: 3rem;
  @include flexCenterCol;
}

//Homepage
.section-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 30rem);
  @media screen and (max-width: 800px) {
    grid-template-rows: repeat(5, 30rem);
    grid-template-columns: 1fr;
  }
}

.calorie {
  &-paper {
    padding: 8rem;
    @media screen and (max-width: 600px) {
      padding: 5rem;
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
  }
}

.recipe {
  &-grid {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &-image {
    height: 5rem;
    width: 100%;
  }
  &-card {
    width: 25rem;
    margin: 1rem;
    height: 23rem;
  }
}

// Recipes
.container-slider {
  @include flexCenter;
  width: 5rem;
}

.diet-inputs {
  @include flexCenter;
}

// Utilities

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2.5rem;
  overflow: auto;
  &-recipe {
    @extend .container;
    max-width: 95%;
    padding-bottom: 1rem;
  }
  &-diet {
    max-width: 1200px;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 1rem 2.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }
}

.flex {
  @include flexCenterCol;
}

.flex-row {
  @include flexCenter;
  justify-content: space-between;
}

.uppercase {
  text-transform: uppercase;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.btn {
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  color: $blue-color;
  border: 3px solid $blue-color;
  transition: $maintransition;
  &-search {
    display: block;
    align-self: center;
  }
  &-secondary {
    @extend .btn;
    @include btnHover;
    &:hover {
      background-color: #fff;
      color: $blue-color;
      border: 3px solid $blue-color;
    }
  }
  &:hover {
    @include btnHover;
  }
}

// Contact

.form-control {
  margin: 2rem 0;
  input {
    &[type="text"],
    &[type="email"] {
      border: 0;
      border-bottom: 1px solid #b4becb;
      width: 100%;
      padding: 3px;
      font-size: 16px;
      transition: $maintransition;
      &:focus {
        outline: none;
        border-bottom: 1px solid $pink-color;
      }
    }
  }
}

// Footer

footer {
  background-color: $blue-color;
  padding: 5rem 0;
  width: 100%;
  p {
    color: #fff;
    font-size: 0.8rem;
    font-weight: 300;
  }
}

.footer {
  &-links {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
  }
}

.socials {
  color: #fff;
  @include flexCenter;
  flex-wrap: wrap;
  i {
    margin: 0 10px;
    padding: 10px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#product-logo {
  font-size: 4rem;
}

.center {
  text-align: center;
}

.media-container {
  position: relative;
}

.side-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: $blue-color;
  width: 50%;
  transition: $maintransition;
}

.navbar-side-link {
  z-index: 20;
  color: #f1f1f1;
  font-size: 2.5rem;
  transition: $maintransition;
  &:hover {
    color: $pink-color;
  }
}

// Media Queries
@media screen and (max-width: 900px) {
  .split-left {
    top: 0;
    width: 100%;
    height: 50%;
    &:hover {
      height: 51%;
    }
  }

  .split-right {
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    &:hover {
      height: 52%;
    }
  }
}

@media screen and (max-width: 800px) {
  .btn-search {
    align-self: center;
  }

  .navbar-links {
    visibility: hidden;
  }

  .hamburger {
    display: block;
  }
}
