* {
  padding: 0;
  margin: 0;
}

html {
  overflow-x: hidden;
  @media screen and (max-width: 800px) {
    font-size: 80%;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  overflow-x: hidden;
}

li {
  list-style: none;
}

i {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

h5 {
  font-size: 1rem;
  opacity: 0.9;
}
